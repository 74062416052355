/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Container, Row, Col} from "react-bootstrap";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(Container, {
    style: {
      background: "AliceBlue"
    }
  }, React.createElement(_components.h1, null, "Encounter"), React.createElement(_components.p, null, "A big monster appears before you."), React.createElement(_components.p, null, "Do you want to"), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "./fight"
  }, "Fight it")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "./run"
  }, "Run")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
